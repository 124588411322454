<form *ngIf="myForm" [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onSubmit()">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Configurações</span>
        </div>
    </mat-toolbar>
    <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
         fxLayoutAlign="space-between stretch">
        <div fxLayout="column" class="p-15" fxLayoutAlign="start start" *ngIf="!loading">

            <div *ngIf="token && !editToken">
                <div class=" mt-15">
                    API Token: <b>{{ token }}*****</b>
                </div>
                <button type="button" class="mt-5 mb-30" mat-raised-button color="primary" (click)="onEditToken()">
                    Editar token
                </button>
            </div>
            <mat-form-field appearance="outline" class="full-width" *ngIf="!token || editToken">
                <mat-label>Token</mat-label>
                <input matInput formControlName="token" placeholder="Token">
                <mat-error>
                    {{ myForm.get('token').errors | error }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width mt-15">
                <mat-label>instructions</mat-label>
                <textarea matInput formControlName="instructions" rows="4"></textarea>
                <mat-error>
                    {{ myForm.get('instructions').errors | error }}
                </mat-error>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="start center" class="full-width mt-15">
                <mat-form-field class="w-300" appearance="outline" *appIsAdmin>
                    <mat-label>model</mat-label>
                    <mat-select placeholder="model" formControlName="model">
                        <mat-option *ngFor="let model of models" [value]="model.name">
                            {{ model?.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button type="button" mat-raised-button color="warn" (click)="onDeleteModel()" class="mr-80 mb-20 ml-15">Delete</button>
                <button type="button" mat-raised-button color="accent" (click)="onCreateNewModel()" class=" mb-20">Criar novo</button>
            </div>


        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
            <button type="submit" mat-raised-button color="accent" [disabled]="myForm.pristine || !myForm.valid"
                    aria-label="Salvar">Salvar
            </button>
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>
    </div>
</form>