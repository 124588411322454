import {Injectable, NgZone} from '@angular/core';
import {BaseDao} from '../../../base-dao';
import {DomainPathService} from '../../../domain-path/domain-path.service';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {AiUser} from '../../../models-ai';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class AiUserDaoService extends BaseDao<any> {
    private aiURL = '';
    constructor(
        private domainPathService: DomainPathService,
        http: R2CloudHttpApiService,
        validatorDAO: KluhManagerValidator,
        private ngZone: NgZone) {
        super(http, validatorDAO, '');
        this.aiURL = this.domainPathService.aiURL;
    }

    public init(): AiUser {
        return {
            managerUserId: null,
            customerGroupId: null,
            aiUserGroupIds: null,
            createdAt: null,
            updatedAt: null,
        };
    }

    save(entity: AiUser): Observable<AiUser> {
        return this.http.put<AiUser>(this.aiURL + 'ai-user/', entity, true);
    }

}
