import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ComponentCleaner} from '../../../component-cleaner';
import {CustomerGroupAiConfig, Model} from '../../../models-ai';
import {CustomerGroupAiConfigDaoService} from './customerGroupAiConfig.dao.service';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {convertToFormGroup, CrudOperationWrapper} from '../../../helpers/kluh';
import {ModelDaoService} from '../model/model.dao.service';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {filter} from 'rxjs/operators';
import {ModelCreateComponent} from '../model/create/model-create.component';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-ai-config',
    templateUrl: './ai-config.component.html',
    styleUrls: ['./ai-config.component.scss']
})
export class AiConfigComponent extends ComponentCleaner {
    myForm: FormGroup;
    models: Model[] = [];
    token = '';
    editToken = false;
    loading = false;
    customerGroupAiConfig: CustomerGroupAiConfig;

    constructor(
        public dialogRef: MatDialogRef<AiConfigComponent>,
        private customerGroupService: CustomerGroupService,
        private customerGroupAiConfigDaoService: CustomerGroupAiConfigDaoService,
        private modelDaoService: ModelDaoService,
        private dialog: MatDialog,
        private fb: FormBuilder,
    ) {
        super();
        this.customerGroupAiConfig = this.customerGroupAiConfigDaoService.init();
        this.customerGroupAiConfig.customerGroupId = this.customerGroupService.customerGroup.id;
        this.myForm = this.fb.group(convertToFormGroup(this.customerGroupAiConfig));
        this.myForm.get('customerGroupId').setValidators([Validators.required]);
        this.myForm.get('instructions').setValidators([Validators.required]);
        this.myForm.get('model').setValidators([Validators.required]);
        this.addSubscription(combineLatest([
            this.customerGroupAiConfigDaoService.getConfig(this.customerGroupService.customerGroup.id),
            this.modelDaoService.findAll()
        ]).subscribe(values => {
            const customerGroupAiConfig = values[0];
            this.models = values[1];
            if (customerGroupAiConfig?.customerGroupId) {
                this.token = customerGroupAiConfig.token;
                this.customerGroupAiConfig = customerGroupAiConfig;
                this.customerGroupAiConfig.token = null;
                this.myForm = this.fb.group(convertToFormGroup(this.customerGroupAiConfig));
            } else {
                this.customerGroupAiConfig.customerGroupId = this.customerGroupService.customerGroup.id;
            }
        }));
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        const customerGroupAiConfig: CustomerGroupAiConfig = this.myForm.value;
        this.customerGroupAiConfigDaoService.save(customerGroupAiConfig).subscribe((customerGroupAiConfigFromDao) => {
            if (customerGroupAiConfigFromDao) {
                this.dialogRef.close({
                    data: customerGroupAiConfigFromDao,
                    operation: 'CREATE'
                });
            }
        });
    }

    onEditToken(): void {
        this.editToken = true;
    }

    onDeleteModel(): void {
        const model: Model = this.findModelByName(this.myForm.get('model').value);
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar esse model?<br><br><b>' + model.name + '</b>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.modelDaoService.delete(model).subscribe(_ => {
                    this.models = this.models.filter(value => value.id !== model.id);
                });
            }
        });
    }

    private findModelByName(name: string): Model {
        return this.models.find(m => m.name === name);
    }

    onCreateNewModel(): void {
        const dialogRef = this.dialog.open(ModelCreateComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {}
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper) => {
            if (result.operation === 'CREATE') {
                this.models.push(result.data);
                this.myForm.get('model').setValue(result.data.name);
            }
        }));
    }
}
