import {Component, ElementRef, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ImageFileService} from '../../image-file/image-file-service';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {CustomerGroup, FirebaseUserDetails, ManagerUser, SearchCriteria} from '../../../models';
import {MatMenuTrigger} from '@angular/material/menu';
import {SafeHtml} from '@angular/platform-browser';
import {AiUserGroup, Message, Thread} from '../../../models-ai';
import {FormControl} from '@angular/forms';
import {ThreadService} from '../thread/thread.service';
import {debounceTime, distinctUntilChanged, filter, finalize, take} from 'rxjs/operators';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ComponentCleaner} from '../../../component-cleaner';
import {AiUserGroupDaoService} from '../ai-user-group/ai-user-group.dao.service';
import {Observable} from 'rxjs/internal/Observable';
import {ThreadDaoService} from '../thread/thread.dao.service';
import {MessageDaoService} from '../message/message.dao.service';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {CrudOperationWrapper, updateQueryString} from '../../../helpers/kluh';
import {AiUserGroupEditComponent} from '../ai-user-group/ai-user-group-edit/ai-user-group-edit.component';
import {AiConfigComponent} from '../ai-config/ai-config.component';
import {AiUserGroupFileEditComponent} from '../ai-user-group-file/ai-user-group-file-edit/ai-user-group-file-edit.component';
import {AiSoftwareCompanyFileEditComponent} from '../ai-software-company-file/ai-software-company-file-edit/ai-software-company-file-edit.component';
import {CustomerGroupAiConfigDaoService} from '../ai-config/customerGroupAiConfig.dao.service';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
    selector: 'app-chat-ai',
    templateUrl: './chat-ai.component.html',
    styleUrls: ['./chat-ai.component.scss']
})
export class ChatAiComponent extends ComponentCleaner {
    user: FirebaseUserDetails;
    message: SafeHtml = '';
    fileCitations: string[] = [];
    threads: Thread[] = [];
    aiUserGroups: AiUserGroup[] = [];
    currentThread: Thread;
    messages: Message[] = [];
    messageCtrl: FormControl = new FormControl();
    loadingConfig = false;
    loading = false;
    loadingMessage = false;
    aiGroupControl: FormControl = new FormControl();
    searchControl: FormControl = new FormControl();
    privateThreadControl: FormControl = new FormControl();
    customerGroup: CustomerGroup;
    hasPermission = false;
    isAdmin = false;
    hasConfiguration = true;
    threadPage = 0;
    threadPageLoading = false;
    startThreadBase64Id = '';
    limitThreads = 30;


    constructor(
        private dialog: MatDialog,
        public imageFileService: ImageFileService,
        private userAuthorityDaoService: UserAuthorityDaoService,
        private threadService: ThreadService,
        private aiUserGroupDaoService: AiUserGroupDaoService,
        private threadDaoService: ThreadDaoService,
        private messageDaoService: MessageDaoService,
        private adminService: R2CloudAdminService,
        private managerUserDaoService: ManagerUserDaoService,
        private customerGroupService: CustomerGroupService,
        private customerGroupAiConfigDaoService: CustomerGroupAiConfigDaoService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        super();
        this.message = '';
        this.fileCitations = [];
        this.initValues();
        this.aiGroupControl.valueChanges.subscribe((value) => {
            if (value) {
                this.currentThread.aiUserGroupId = value;
            }
        });
        this.privateThreadControl.valueChanges.subscribe((value) => {
            this.currentThread.private = !!value;
        });

        this.searchControl.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe(_ => {
            this.threadPage = 0;
            this.loadAllThreads();
        });

        this.route.queryParams.pipe(take(1)).subscribe(params => {
            this.startThreadBase64Id = params['thread'];
        });

    }

    @ViewChild('scrollableDiv') private scrollableDiv!: ElementRef;
    @ViewChildren(MatMenuTrigger, {read: ElementRef}) triggers: QueryList<ElementRef>;

    newThread(): void {
        let selectedThread = this.threads.find(t => t.title === '');
        if (!selectedThread) {
            const thread = this.threadService.initThread();
            thread.title = '';
            thread.managerUserId = this.user.managerUser.id;
            this.threads.unshift(thread);
            selectedThread = thread;
        }
        this.openThread(selectedThread);
    }

    openThread(thread: Thread): void {
        this.setSelectedThread(thread);
        this.messages = [];
        if (thread.id) {
            updateQueryString(this.router, this.route, 'thread', thread.id.toString(), true);
            this.messageDaoService.getMessages(thread.id).subscribe((messages) => {
                this.messages = messages;
                this.setScrollDown();
            });
        } else {
            updateQueryString(this.router, this.route, 'thread', '', true);
        }
    }

    private scrollToBottom(): void {
        setTimeout(() => {
            const div = this.scrollableDiv.nativeElement;
            div.scrollTop = div.scrollHeight + 90;
        }, 300);

    }

    private initValues(): void {
        this.addSubscription(this.loadUserAndCustomerGroup().subscribe(values => {
            this.loadingConfig = true;
            this.processUserAndCustomerGroup(values);
        }));
    }

    private loadUserAndCustomerGroup(): Observable<[FirebaseUserDetails, CustomerGroup, boolean]> {
        return combineLatest([
            this.userAuthorityDaoService.getMe(),
            this.customerGroupService.get(),
            this.userAuthorityDaoService.isAdminUser(),
        ]);
    }

    private processUserAndCustomerGroup(values: [FirebaseUserDetails, CustomerGroup, boolean]): void {
        this.hasConfiguration = false;
        const [user, customerGroup, isAdmin] = values;
        this.isAdmin = isAdmin;
        this.customerGroup = customerGroup;
        this.hasPermission = this.checkUserPermission(user, customerGroup);

        if (this.hasPermission || this.isAdmin) {
            this.user = user;
            setTimeout(() => {
                this.loadConfiguration(customerGroup.id);
            }, 2000);
        }
    }

    private checkUserPermission(user: FirebaseUserDetails, customerGroup: CustomerGroup): boolean {
        return customerGroup?.customerIds.some(id => id === user?.managerUser.customerId) || false;
    }

    private loadConfiguration(customerGroupId: number): void {
        this.addSubscription(
            this.customerGroupAiConfigDaoService.getConfig(customerGroupId).subscribe(config => {
                this.loadingConfig = false;
                if (config?.customerGroupId) {
                    this.hasConfiguration = true;
                    this.loadManagerUsers(customerGroupId);
                }
            })
        );
    }

    private loadManagerUsers(customerGroupId: number): void {
        this.addSubscription(
            this.managerUserDaoService.findAllByCustomerGroupId(customerGroupId).subscribe(managerUsers => {
                if (managerUsers) {
                    this.adminService.managerUsers = managerUsers;
                    this.loadAllThreads(2000);
                }
            })
        );
    }


    private loadAllThreads(timeout: number = 1): void {
        this.threadPageLoading = true;
        setTimeout(() => {
            this.getAllThreads();
        }, timeout);
    }

    private getAllThreads(): void {
        const searchCriteria: SearchCriteria = {
            field: 'search',
            value: this.searchControl.value,
            fieldOrder: null,
            order: null,
            limit: this.limitThreads,
            page: this.threadPage,
        };
        this.threadDaoService.getThreads(this.customerGroup.id, searchCriteria).subscribe((threads) => {
            this.getAllAiUserGroups();
            this.threadPageLoading = false;
            this.scrollToBottom();
            if (searchCriteria.page === 0) {
                this.threads = threads;
            } else {
                this.threads.push(...threads);
            }
            this.newThread();
            if (searchCriteria.value) {
                this.threads = this.threads.filter(t => t.title !== '');
                if (this.threads.length > 0) {
                    this.openThread(this.threads[0]);
                }
            }
            this.selectedThreadByStartThreadId();
        });
    }

    private selectedThreadByStartThreadId(): void {
        if (this.startThreadBase64Id) {
            const thread = this.threads.find(t => t.id === +window.atob(this.startThreadBase64Id));
            this.startThreadBase64Id = null;
            this.openThread(thread);
        }
    }

    findAiUserGroupById(id: number): AiUserGroup | undefined {
        return this.aiUserGroups.find(aiUserGroup => aiUserGroup.id === id);
    }

    private getAllAiUserGroups(): void {
        this.aiUserGroupDaoService.getAll().subscribe(aiUserGroups => {
            this.aiUserGroups = aiUserGroups;
            const aiUserGroupDefault = this.aiUserGroups.find(g => g.default);
            if (aiUserGroupDefault) {
                this.aiGroupControl.setValue(aiUserGroupDefault.id);
            }
        });
    }

    private getThreadByProviderThreadId(providerThreadId: string): void {
        if (providerThreadId) {
            this.threadDaoService.getThreadByProviderThreadId(providerThreadId).subscribe((thread) => {
                if (thread) {
                    const index = this.threads.findIndex((value) => value === this.currentThread);
                    if (index > -1) {
                        this.currentThread = thread;
                        this.threads[index] = thread;
                        this.loadingMessage = false;
                    }
                }
            });
        }
    }

    deleteByThreadId(thread: Thread): void {
        this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar essa pergunta?<br><br>"' + thread.title + '"',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.threads = this.removeThreadFromList(this.threads, thread.id);
                this.threadDaoService.deleteThread(thread).subscribe((_) => {
                    this.messages = [];
                });
            }
        }));
    }

    private removeThreadFromList(thread: Thread[], threadId: number): Thread[] {
        return thread.filter(thr => thr.id !== threadId);
    }

    getClass(message: Message): string {
        return message.providerRole;
    }

    trackByFn(index: number, item: MessageContent): string {
        return item.type;
    }

    private setSelectedThread(thread: Thread): void {
        this.message = '';
        this.fileCitations = [];
        this.currentThread = thread;
    }

    getMessageText(message: Message): MessageContent[] {
        const cleanedJsonString = message.providerContent.replace(/\n/g, ' ');
        if (this.isJsonString(cleanedJsonString)) {
            const parse = JSON.parse(cleanedJsonString);
            if (Array.isArray(parse)) {
                return parse as MessageContent[];
            } else {
                return [parse] as MessageContent[];
            }
        } else {
            return [{
                type: 'text',
                text: {
                    value: cleanedJsonString,
                    annotations: [],
                },
            }] as MessageContent[];
        }
    }

    isJsonString(str: string): boolean {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    getManagerUser(managerUserId: number): ManagerUser {
        return this.adminService.managerUsers.find(user => user.id === managerUserId);
    }

    openMenu(managerUserId: number): void {
        // tslint:disable-next-line:no-shadowed-variable
        const trigger = this.triggers.toArray().find((trigger, index) => {
            return this.adminService.managerUsers[index]?.id === managerUserId;
        });
        if (trigger) {
            const matMenuTrigger = trigger.nativeElement.querySelector('button[mat-menu-trigger-for]');
            matMenuTrigger?.click();
        }
    }


    sendMessage(): void {
        let messageInner = '';
        let fileCitations = '';
        const messageText = this.messageCtrl.value;
        if (!this.currentThread.title) {
            this.currentThread.title = messageText;
        }
        this.messageCtrl.setValue('');
        const message = this.messageDaoService.createMessageUser(this.currentThread, this.user.managerUser.id, messageText);
        this.messages.push(message);
        this.messages.push(this.messageDaoService.createMessageAssistant(this.currentThread));
        this.setScrollDown();
        this.loading = true;
        this.loadingMessage = true;
        let lastThreadId = '';
        let aiUserGroupId = this.currentThread.aiUserGroupId;
        if (!aiUserGroupId) {
            aiUserGroupId = this.aiUserGroups.find(g => g.default)?.id;
        }
        const isPrivate = this.currentThread.private;
        this.messageDaoService.sendMessages(message, aiUserGroupId, isPrivate).pipe(
            finalize(() => {
                this.getThreadByProviderThreadId(lastThreadId);
            })
        ).subscribe(data => {
            this.loading = false;
            if (!lastThreadId) {
                lastThreadId = this.extractThreadId(data);
            }
            const text = this.extractValues(data);
            messageInner += this.breakLineToHtml(text);
            this.extractAnnotations(data).forEach(annotation => {
                fileCitations += annotation;
            });
            const index = this.messages.length - 1;
            this.messages[index].providerContent = messageInner + '<br><br> ' + fileCitations;
            this.setScrollDown();
        }, error => {
            console.error(error);
            this.loading = false;
            this.loadingMessage = false;
        });

    }

    breakLineToHtml(text: string): string {
        return text.replace(/(\r\n|\r|\n|\\n)/g, '<br>');
    }

    extractValues(input: string): string {
        if (input.indexOf('"id":"msg_') === -1 || input.indexOf('"status":"completed"') > 0 || input.indexOf('"content":[]') > 0) {
            return '';
        }
        const values: string[] = [];
        try {
            const regex = /({.*?}})(?={|$)/g;
            const matches = input.match(regex);
            matches?.forEach(match => {
                try {
                    const obj = JSON.parse(match);
                    const value = obj.delta.content[0].text.value;
                    values.push(value);
                } catch (e) {
                }
            });
        } catch (e) {
            return '';
        }
        return values.join('');
    }

    extractAnnotations(input: string): string[] {
        if (input.indexOf('file_citation') === -1) {
            return [];
        }
        const fileCitationRegex = /"file_citation":\s*({[^}]*})/g;
        let match;
        const results = [];

        while ((match = fileCitationRegex.exec(input)) !== null) {
            results.push(match[1]);
        }

        return results;
    }

    extractThreadId(input: string): string | null {
        if (input.indexOf('thread_id') === -1) {
            return '';
        }
        const regex = /"thread_id":"(thread_[^"]+)"/;
        const match = regex.exec(input);
        return match ? match[1] : null;
    }

    private setScrollDown(): void {
        setTimeout(() => {
            const scrollableDiv = document.getElementById('chat');
            scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
        }, 200);
    }

    markdownToHtml(text: string): string {
        let html = text;
        html = html.replace(/(\r\n|\r|\n|\\n)/g, '<br>');

        html = html.replace(/^###### (.*$)/gim, '<h6>$1</h6>');
        html = html.replace(/^##### (.*$)/gim, '<h5>$1</h5>');
        html = html.replace(/^#### (.*$)/gim, '<h4>$1</h4>');
        html = html.replace(/^### (.*$)/gim, '<h3>$1</h3>');
        html = html.replace(/^## (.*$)/gim, '<h2>$1</h2>');
        html = html.replace(/^# (.*$)/gim, '<h1>$1</h1>');


        html = html.replace(/```([^`]+)```/gim, '<pre><code>$1</code></pre>');
        html = html.replace(/`([^`]+)`/gim, '<code>$1</code>');


        html = html.replace(/^> (.*$)/gim, '<blockquote>$1</blockquote>');


        html = html.replace(/^\d+\.\s+(.*)/gim, '<ol><li>$1</li></ol>');
        html = html.replace(/^\d+\.\s+(.*)/gim, '<li>$1</li>');


        html = html.replace(/^\s*\n\*\s+(.*)/gim, '<ul>\n<li>$1</li>\n</ul>');
        html = html.replace(/^\*\s+(.*)/gim, '<li>$1</li>');


        html = html.replace(/\*\*(.*)\*\*/gim, '<strong>$1</strong>');
        html = html.replace(/\*(.*)\*/gim, '<em>$1</em>');

        html = html.replace(/\[(.*?)]\((.*?)\)/gim, '<a href="' + '$2' + '">' + '$1' + '</a>');
        html = html.replace(/!\[(.*?)]\((.*?)\)/gim, '<img src="' + '$2' + '" alt="$1" />');

        html = html.replace(/^\s*(\S.*)\s*$/gim, '<p>$1</p>');

        html = html.replace(/^---$/gim, '<hr />');

        html = html.replace(/\n/gim, '<br />');

        return html.trim();
    }

    aiUserGroupEdit(): void {
        const dialogRef = this.dialog.open(AiUserGroupEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-xx-large',
            data: {}
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper) => {
            if (result.operation === 'SAVE' || result.operation === 'CREATE') {
                this.loadAllThreads();
            }
        }));
    }

    aiConfigEdit(): void {
        const dialogRef = this.dialog.open(AiConfigComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {}
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper) => {
            if (result) {
                this.initValues();
            }
        }));
    }

    aiUserGroupFiles(): void {
        const dialogRef = this.dialog.open(AiUserGroupFileEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-xx-large',
            data: {}
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((_: CrudOperationWrapper) => {
        }));
    }

    aiSoftwareCompaniesFiles(): void {
        const dialogRef = this.dialog.open(AiSoftwareCompanyFileEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-xx-large',
            data: {}
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((_: CrudOperationWrapper) => {
        }));
    }

    deleteMessage(message: Message): void {
        this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar essa mensagem?<br><br>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.messageDaoService.delete(message).subscribe((_) => {
                    this.messages = this.messages.filter(value => value.id !== message.id);
                });
            }
        }));
    }

    hasMoreThreadsThanLimit(): boolean {
        return this.threads.length >= this.limitThreads;
    }

    getMoreThreads(): void {
        this.threadPage++;
        this.loadAllThreads();
    }
}

export interface MessageContent {
    type: string | null;
    text: MessageContentText | null;
}

export interface MessageContentText {
    value: string | null;
    annotations: MessageContentTextAnnotation[] | null;
}

export interface MessageContentTextAnnotation {
    type: string | null;
    text: string | null;
    start_index: number | null;
    end_index: number | null;
    file_citation: MessageContentTextAnnotationFileCitation | null;
}

export interface MessageContentTextAnnotationFileCitation {
    file_id: string | null;
}
