import {Component, Inject} from '@angular/core';
import {ComponentCleaner} from '../../../../component-cleaner';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {R2CloudAdminService} from '../../../r2-cloud-admin/r2-cloud-admin.service';
import {ManagerUser} from '../../../../models';
import {ImageFileService} from '../../../image-file/image-file-service';
import {AiUserGroup, Model} from '../../../../models-ai';
import {AiUserGroupDaoService} from '../ai-user-group.dao.service';
import {filter} from 'rxjs/operators';
import {CrudOperationWrapper} from '../../../../helpers/kluh';
import {AiUserGroupModalComponent} from './ai-user-group-modal/ai-user-group-modal.component';
import {AiUserModalComponent} from './ai-user-modal/ai-user-modal.component';
import {CustomerGroupService} from '../../../customer-group/customer-group.service';
import {ModelDaoService} from '../../model/model.dao.service';

@Component({
    selector: 'app-ai-user-group-edit',
    templateUrl: './ai-user-group-edit.component.html',
    styleUrls: ['./ai-user-group-edit.component.scss']
})
export class AiUserGroupEditComponent extends ComponentCleaner {
    managerUsers: ManagerUser[] = [];
    aiUserGroups: AiUserGroup[] = [];
    models: Model[] = [];

    constructor(
        public dialogRef: MatDialogRef<AiUserGroupEditComponent>,
        public imageFileService: ImageFileService,
        private adminService: R2CloudAdminService,
        private aiUserGroupDaoService: AiUserGroupDaoService,
        private customerGroupService: CustomerGroupService,
        private modelDaoService: ModelDaoService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        super();
        this.getInitValues();
    }

    private getInitValues(): void {
        this.managerUsers = [];
        this.aiUserGroups = [];
        this.aiUserGroupDaoService.getAllByCustomerGroup(this.customerGroupService.customerGroup.id).subscribe(aiUserGroups => {
            this.aiUserGroups = aiUserGroups;
            this.managerUsers = this.adminService.managerUsers;
        });
        this.modelDaoService.findAll().subscribe(models => {
            this.models = models;
        });
    }

    getManagerUser(managerUserId: number): ManagerUser {
        return this.adminService.managerUsers.find(user => user.id === managerUserId);
    }

    getAiUserGroupByManagerUserId(managerUserId: number): AiUserGroup[] {
        return this.aiUserGroups.filter((g) => (g.managerUserIds.some(id => id === managerUserId) && g.name.toLowerCase() !== 'todos'));
    }

    getAiUserGroupEditable(): AiUserGroup[] {
        return this.aiUserGroups.filter((g) => g.name.toLowerCase() !== 'todos');
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    createAiUserGroup(): void {
        this.aiUserGroupEdit(null);
    }

    private updateAiUserGroupOfList(aiUserGroup: AiUserGroup): void {
        const aiUserGroupIndex = this.aiUserGroups.findIndex(a => a.id === aiUserGroup.id);
        if (aiUserGroupIndex !== -1) {
            this.aiUserGroups[aiUserGroupIndex] = aiUserGroup;
        }
    }


    aiUserGroupEdit(aiUserGroup: AiUserGroup | null): void {
        const dialogRef = this.dialog.open(AiUserGroupModalComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                aiUserGroup: aiUserGroup,
                models: this.models,
                managerUsers: this.managerUsers
            }
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper) => {
            const aiUserGroupResult: AiUserGroup = result.data;
            if (result.operation === 'SAVE') {
                this.updateAiUserGroupOfList(aiUserGroupResult);
                this.dialogRef.close({
                    data: this.aiUserGroups,
                    operation: 'SAVE'
                });
            } else if (result.operation === 'CREATE') {
                this.aiUserGroups.push(aiUserGroupResult);
                this.dialogRef.close({
                    data: this.aiUserGroups,
                    operation: 'CREATE'
                });
            } else if (result.operation === 'DELETE') {
                this.aiUserGroups = this.aiUserGroups.filter(item => item.id !== aiUserGroupResult.id);
                this.dialogRef.close({
                    data: this.aiUserGroups,
                    operation: 'DELETE'
                });
            }
        }));
    }

    aiUserEdit(managerUser: ManagerUser): void {
        const dialogRef = this.dialog.open(AiUserModalComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                managerUser: managerUser,
                aiUserGroups: this.aiUserGroups
            }
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper) => {
            if (result.operation === 'SAVE') {
                this.getInitValues();
            }
        }));
    }
}
