import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ComponentCleaner} from '../../../../component-cleaner';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {AiSoftwareCompanyFile} from '../../../../models-ai';
import {FormControl} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ImageFileService} from '../../../image-file/image-file-service';
import {UserAuthorityDaoService} from '../../../user-authority/user-authority-dao.service';
import {concatMap, filter, startWith} from 'rxjs/operators';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {from} from 'rxjs/internal/observable/from';
import {R2CloudAdminService} from '../../../r2-cloud-admin/r2-cloud-admin.service';
import {SoftwareCompanyDaoService} from '../../../marketplace/software-company-create-and-edit/software-company.dao.service';
import {AiSoftwareCompanyFileDaoService} from '../ai-software-company-file-dao.service';

@Component({
    selector: 'app-ai-software-company-file-edit',
    templateUrl: './ai-software-company-file-edit.component.html',
    styleUrls: ['./ai-software-company-file-edit.component.scss']
})
export class AiSoftwareCompanyFileEditComponent extends ComponentCleaner implements OnInit {
    @ViewChild('fileInput')
    fileInput: ElementRef<HTMLInputElement>;

    isAdmin = false;

    uploading = false;
    displayedColumns: string[] = ['id', 'name', 'size', 'createdAt', 'action'];
    dataSource: AiSoftwareCompanyFileDaoService;

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;
    totalDocs = 0;


    softwareCompanyIdsControl: FormControl = new FormControl();


    constructor(
        public dialogRef: MatDialogRef<AiSoftwareCompanyFileEditComponent>,
        public imageFileService: ImageFileService,
        private aiSoftwareCompanyFileDaoService: AiSoftwareCompanyFileDaoService,
        private dialog: MatDialog,
        private userAuthorityDaoService: UserAuthorityDaoService,
        public adminService: R2CloudAdminService,
        public softwareCompanyDaoService: SoftwareCompanyDaoService,
    ) {
        super();
        this.dataSource = this.aiSoftwareCompanyFileDaoService;
        this.aiSoftwareCompanyFileDaoService.total$.subscribe(total => {
            this.totalDocs = total;
        });
    }

    ngOnInit(): void {
        this.userAuthorityDaoService.isAdminUser().subscribe((isAdmin) => {
            this.isAdmin = isAdmin;
        });
        this.softwareCompanyDaoService.get().subscribe((softwareCompanies) => {
            this.adminService.softwareCompanies = softwareCompanies;
        });

        this.softwareCompanyIdsControl.valueChanges.subscribe(softwareCompanyId => {
            if (softwareCompanyId) {
                this.loadTableData();
            }
        });
    }

    private clearFiles(): void {
        this.fileInput.nativeElement.value = '';
    }

    private loadTableData(): void {
        this.clearFiles();
        this.paginator.page.pipe(startWith(
            {pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize}
        ), filter(value => !!value)).subscribe((page: PageEvent) => {
            this.aiSoftwareCompanyFileDaoService.load('softwareCompanyId', this.softwareCompanyIdsControl.value, page, false);
        });
    }

    hasNewFiles(): boolean {
        return this.aiSoftwareCompanyFileDaoService.getAll()?.some(file => file.id === null);
    }


    sendFiles(): void {
        const aiSoftwareCompanyFiles = this.aiSoftwareCompanyFileDaoService.getAll()?.filter(file => {
            return file.id === null;
        });
        for (const aiFile of aiSoftwareCompanyFiles) {
            aiFile.id = -1;
            this.aiSoftwareCompanyFileDaoService.updateFile(aiFile);
        }
        this.uploadAllFiles(aiSoftwareCompanyFiles);

    }


    delete(aiFile: AiSoftwareCompanyFile): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar esse arquivo?<br><br><b>' + aiFile.name + '</b>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.aiSoftwareCompanyFileDaoService.delete(aiFile).subscribe(_ => {

                });
            }
        });


    }

    uploadAllFiles(aiSoftwareCompanyFiles: AiSoftwareCompanyFile[]): void {
        this.uploading = true;
        from(aiSoftwareCompanyFiles)
            .pipe(
                concatMap(file => this.aiSoftwareCompanyFileDaoService.uploadFile(file.data, file))
            )
            .subscribe({
                next: (result) => {
                    this.aiSoftwareCompanyFileDaoService.updateFile(result);
                },
                error: (erro) => {
                    this.uploading = false;
                    console.error('Erro ao processar os objetos:', erro);
                },
                complete: () => {
                    this.uploading = false;
                }
            });
    }

    onAddFiles(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        this.handleFiles(currentTarget.files);
    }

    private handleFiles(files: FileList): void {
        const softwareCompanyId = this.softwareCompanyIdsControl.value;
        for (let i = 0; i < files.length; i++) {
            const aiFile: AiSoftwareCompanyFile = {
                id: null,
                managerUserId: null,
                fileUUID: null,
                hash: null,
                softwareCompanyId: softwareCompanyId,
                data: files[i],
                name: files[i].name,
                size: files[i].size,
                createdAt: null,
            };
            this.aiSoftwareCompanyFileDaoService.addFile(aiFile);
        }
    }

    openFileInput(): void {
        this.fileInput.nativeElement.click();
    }

    onCancel(): void {
        this.dialogRef.close();
    }

}
