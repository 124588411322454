<div class="chat-ai" fxLayout="row" fxLayoutAlign="start start">
    <div class="left-content" fxLayout="column" fxLayoutAlign="start start">
        <div class="threads-content" fxFlex fxLayout="column" fxLayoutAlign="start start">
            <mat-spinner *ngIf="loadingConfig" [diameter]="24" color="primary" class="m-20"></mat-spinner>
            <button type="button" mat-raised-button color="accent" class="m-20" (click)="aiConfigEdit()" *ngIf="!hasConfiguration && !loadingConfig">
                Configuração primária da AI
            </button>

            <div class="threads-head" fxFlex fxLayout="column" fxLayoutAlign="start start" *ngIf="(hasPermission || isAdmin) && hasConfiguration && !loadingConfig">
                <div fxFlex fxLayout="row" fxLayoutAlign="space-between start" class="full-width search-threads">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img class="avatar mr-10 mr-sm-16"
                             src="{{imageFileService.getManagerUserPicture(user.managerUser)}}?w=150&square=true"
                             *ngIf="user;" alt="user">
                        <span class="username mr-12 text-max-120" fxHide fxShow.gt-sm
                              *ngIf="user && user.managerUser?.displayName; else noDisplayName">{{ user.managerUser?.displayName }}</span>
                        <ng-template #noDisplayName>
                            <span class="username mr-12 text-max-120" fxHide fxShow.gt-sm>{{ user?.email }}</span>
                        </ng-template>
                    </div>
                    <div fxFlex fxLayout="row" fxLayoutAlign="end start" class="full-width">
                        <button class="send-message-button" mat-icon-button aria-label="Menu"
                                [matMenuTriggerFor]="menu1">
                            <mat-icon class="secondary-text">more_vert</mat-icon>
                        </button>
                        <mat-menu #menu1="matMenu" style="max-width: 372px">
                            <button mat-menu-item (click)="aiUserGroupEdit()">
                                <mat-icon>user_group</mat-icon>
                                <span>Grupos de usuários</span>
                            </button>
                            <button mat-menu-item (click)="aiUserGroupFiles()">
                                <mat-icon>library_add</mat-icon>
                                <span>Arquivos da empresa</span>
                            </button>
                            <button mat-menu-item (click)="aiSoftwareCompaniesFiles()" *appIsAdmin>
                                <mat-icon>library_add</mat-icon>
                                <span>Arquivos das empresas de software</span>
                            </button>
                            <button mat-menu-item (click)="aiConfigEdit()" *appIsAdmin>
                                <mat-icon>settings</mat-icon>
                                <span>Configurações</span>
                            </button>
                        </mat-menu>

                        <button class="send-message-button" mat-icon-button aria-label="Nova pergunta"
                                (click)="newThread()" *ngIf="hasPermission">
                            <mat-icon class="secondary-text">add</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="thread-search" *ngIf="hasPermission">
                    <div class="round-field-wrapper mb-20" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <div class="round-field" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon>search</mat-icon>
                            <input placeholder="Buscar perguntas" matInput fxFlex  [formControl]="searchControl">
                        </div>
                    </div>
                </div>
            </div>
            <div class="threads" id="threads"  *ngIf="hasPermission && !loadingConfig" #scrollableDiv >
                <div class="thread" [class.selected]="currentThread?.id === thread.id" *ngFor="let thread of threads"
                     (click)="openThread(thread)">
                    <div class="overlay" *ngIf="thread.id">
                        <div class="float-right">
                            <button class="more-delete-button" mat-icon-button aria-label="Menu"
                                    [matMenuTriggerFor]="menu" (click)="openMenu(thread.id)">
                                <mat-icon class="secondary-text">more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" style="max-width: 372px">
                                <button mat-menu-item (click)="deleteByThreadId(thread)">
                                    <mat-icon>delete_forever</mat-icon>
                                    <span>Deletar</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                    <div fxFlex fxLayout="row" fxLayoutAlign="start start">
                            <img class="avatar mr-10 mr-sm-16"
                                 src="{{imageFileService.getManagerUserPicture(getManagerUser(thread.managerUserId))}}?w=150&square=true" alt="avatar">
                        <div class="user-message-content width-100-percent">
                            <div><b>{{ getManagerUser(thread?.managerUserId)?.displayName }}</b> <span
                                class="ml-10 font-size-12 float-right"> {{ thread.createdAt | timeDiff: 'medium' }}</span>
                            </div>
                            <div class="mt-5 text-max-265">{{ thread.title }}</div>
                        </div>
                    </div>
                    <div class="group-thread text-max-80" *ngIf="thread.aiUserGroupId > 0 && thread.private">
                        Eu
                    </div>
                    <div class="group-thread text-max-80" *ngIf="thread.aiUserGroupId > 0 && !thread.private">
                        {{ findAiUserGroupById(thread.aiUserGroupId)?.name }}
                    </div>
                </div>
                <button type="button" mat-raised-button color="accent"
                        style="margin-left: 130px;margin-top: 20px;" (click)="getMoreThreads()" *ngIf="!threadPageLoading && hasMoreThreadsThanLimit()">mais</button>
                <mat-spinner *ngIf="threadPageLoading" [diameter]="24" color="primary" class="mt-10"></mat-spinner>
            </div>
        </div>
    </div>
    <div class="right-content" fxLayout="column" fxLayoutAlign="start start" *ngIf="hasPermission && !loadingConfig">
        <div class="chat" fxLayout="column" fxLayoutAlign="start center" id="chat">
            <div *ngFor="let message of messages" [ngClass]="getClass(message)">
                <div fxFlex fxLayout="row" fxLayoutAlign="start start">
                    <img *ngIf="message.providerRole !== 'user'" class="robot"
                         src="/assets/images/logos/r2/medium/r2-cloud-robo-medium.png" alt="assistant">
                    <img *ngIf="message.providerRole === 'user'" class="avatar mr-10 mr-sm-16"
                         src="{{imageFileService.getManagerUserPicture(getManagerUser(message.managerUserId))}}?w=150&square=true"
                         alt="user">
                    <div *ngFor="let messageValue of getMessageText(message); trackBy: trackByFn"
                         class="width-100-percent">
                        <div class="ml-10">
                            <div *ngIf="message.providerRole !== 'user'"><b class="pt-5">R2 AI</b>
                                <mat-spinner *ngIf="!message.providerContent" [diameter]="24" color="primary"
                                             class="mt-10"></mat-spinner>
                                <mat-icon class="icon-del" (click)="deleteMessage(message)" >delete_forever</mat-icon>
                                <span class="float-right font-size-12 pt-5"
                                      title="{{message.createdAt | date: 'dd/MM/yyyy HH:mm'}}"> {{ message.createdAt | timeDiff: 'medium' }}</span>
                            </div>
                            <div *ngIf="message.providerRole === 'user'">
                                <b class="pt-5">{{ getManagerUser(message.managerUserId)?.displayName }} </b>
                                <mat-icon class="icon-del" (click)="deleteMessage(message)" >delete_forever</mat-icon>
                                <span class="float-right font-size-12 pt-5"
                                      title="{{message.createdAt | date: 'dd/MM/yyyy HH:mm'}}"> {{ message.createdAt | timeDiff: 'medium' }}</span>
                            </div>

                        </div>
                        <div class="ml-10 text"
                             [innerHTML]="markdownToHtml(messageValue.text.value) | safeHtml: 'html'"></div>
                        <div *ngFor="let annotation of messageValue.text.annotations" class="mt-10">
                            <div class="ml-10 anotations" [innerHTML]="annotation.text | safeHtml: 'html'"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="message" class="assistant">
                <div fxFlex fxLayout="row" fxLayoutAlign="start start">
                    <img class="robot"
                         src="/assets/images/logos/r2/medium/r2-cloud-robo-medium.png" alt="assistant">
                    <div class="width-100-percent">
                        <div class="ml-10">
                            <div><b>R2 </b>
                                <span class="float-right font-size-12"></span>
                            </div>
                        </div>
                        <div class="ml-10 text"
                             [innerHTML]="(message + '<br><br>' + fileCitations) | safeHtml: 'html'"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="question" fxLayout="row" fxLayoutAlign="center center">
            <div *ngIf="!currentThread?.id && !loadingMessage; else existsThread"
                 style="max-width: 300px;margin-right: 20px;height: 64px;">
                <div fxFlex fxLayout="row" fxLayoutAlign="start center" style="height: 64px;">
                    <mat-form-field appearance="outline" class="w-150">
                        <mat-label>Grupo</mat-label>
                        <mat-select placeholder="Grupo" [formControl]="aiGroupControl" [disabled] = "privateThreadControl.value || currentThread?.private">
                            <mat-option *ngFor="let aiUserGroup of aiUserGroups" [value]="aiUserGroup.id">
                                {{ aiUserGroup.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-checkbox [formControl]="privateThreadControl" class="ml-10" [value]="'true'">
                        Somente eu
                    </mat-checkbox>
                </div>
                <mat-divider vertical style="height:100%" class="ml-20"></mat-divider>
            </div>
            <ng-template #existsThread>
                <div *ngIf="currentThread.private" fxLayout="column" fxLayoutAlign="center start" style="max-width: 300px;margin-right: 20px;height: 64px;">
                    <div class="font-size-12">Conversa privada</div>
                </div>
                <div *ngIf="!currentThread.private" class="font-size-12"  fxLayout="column" fxLayoutAlign="center start" style="max-width: 300px;margin-right: 20px;height: 64px;">
                    <div><b>Grupo:</b></div>
                    <div>{{ findAiUserGroupById(currentThread.aiUserGroupId)?.name }}</div>
                </div>
            </ng-template>
            <div class="round-field-wrapper round-field-big" fxFlex fxLayout="row" fxLayoutAlign="start center">
                <div class="round-field" fxLayout="row" fxLayoutAlign="start center">
                    <textarea [matTextareaAutosize]="true" matInput fxFlex placeholder="Pergunte ao R2"
                              [formControl]="messageCtrl"></textarea>
                </div>
            </div>
            <button type="button" class="ml-15" mat-raised-button color="primary" (click)="sendMessage()"
                    [disabled]="loadingMessage || (!aiGroupControl.value && !currentThread?.id) || !messageCtrl.value">
                <span>Enviar</span>
            </button>

        </div>
    </div>
</div>