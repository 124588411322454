import {Component, Inject} from '@angular/core';
import {ComponentCleaner} from '../../../../../component-cleaner';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AiUser, AiUserGroup} from '../../../../../models-ai';
import {ManagerUser} from '../../../../../models';
import {FormControl} from '@angular/forms';
import {AiUserDaoService} from '../../../ai-user/ai-user.dao.service';
import {CustomerGroupService} from '../../../../customer-group/customer-group.service';

@Component({
    selector: 'app-ai-user-modal',
    templateUrl: './ai-user-modal.component.html',
    styleUrls: ['./ai-user-modal.component.scss']
})
export class AiUserModalComponent extends ComponentCleaner {

    aiUserGroups: AiUserGroup[] = [];
    aiUserGroupsOfManagerUser: AiUserGroup[] = [];
    managerUser: ManagerUser;
    aiUser: AiUser;
    aiUserGroupIdsControl: FormControl = new FormControl();

    constructor(
        public dialogRef: MatDialogRef<AiUserModalComponent>,
        private aiUserDaoService: AiUserDaoService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public customerGroupService: CustomerGroupService,
    ) {
        super();
        this.aiUserGroups = data.aiUserGroups;
        this.managerUser = data.managerUser;

        this.aiUser = aiUserDaoService.init();
        this.aiUser.managerUserId = this.managerUser.id;

        this.aiUserGroupsOfManagerUser = this.aiUserGroups.filter(g => g.managerUserIds.some(id => id === this.managerUser.id));
        this.aiUserGroupIdsControl.setValue(this.aiUserGroupsOfManagerUser.map(g => g.id));
    }

    removeDefaultAiUserGroup(aiUserGroups: AiUserGroup[]): AiUserGroup[] {
        return aiUserGroups.filter(g => !g.default);
    }


    onCancel(): void {
        this.dialogRef.close();
    }


    onSave(): void {
        this.aiUser.aiUserGroupIds = this.aiUserGroupIdsControl.value;
        this.aiUser.customerGroupId = this.customerGroupService.customerGroup.id;
        if (this.aiUser.customerGroupId) {
            this.aiUserDaoService.save(this.aiUser).subscribe(aiUser => {
                this.dialogRef.close({
                    data: aiUser,
                    operation: 'SAVE'
                });
            });
        } else {

        }
    }

}
