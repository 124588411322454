import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ComponentCleaner} from '../../../../component-cleaner';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ImageFileService} from '../../../image-file/image-file-service';
import {FormControl} from '@angular/forms';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {concatMap, filter, startWith} from 'rxjs/operators';
import {AiUserGroupDaoService} from '../../ai-user-group/ai-user-group.dao.service';
import {AiFile, AiUserGroup} from '../../../../models-ai';
import {from} from 'rxjs/internal/observable/from';
import {AiUserGroupFileDaoService} from '../ai-user-group-file.dao.service';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {UserAuthorityDaoService} from '../../../user-authority/user-authority-dao.service';
import {CustomerGroupService} from '../../../customer-group/customer-group.service';

@Component({
    selector: 'app-ai-user-file-group-edit',
    templateUrl: './ai-user-group-file-edit.component.html',
    styleUrls: ['./ai-user-group-file-edit.component.scss']
})
export class AiUserGroupFileEditComponent extends ComponentCleaner implements OnInit {
    @ViewChild('fileInput')
    fileInput: ElementRef<HTMLInputElement>;

    isAdmin = false;

    uploading = false;
    displayedColumns: string[] = ['id', 'name', 'size', 'createdAt', 'action'];
    dataSource: AiUserGroupFileDaoService;

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;
    totalDocs = 0;
    aiUserGroups: AiUserGroup[] = [];

    aiUserGroupIdControl: FormControl = new FormControl();
    isDefaultControl: FormControl = new FormControl();

    constructor(
        public dialogRef: MatDialogRef<AiUserGroupFileEditComponent>,
        public imageFileService: ImageFileService,
        private aiFileDaoService: AiUserGroupFileDaoService,
        private aiUserGroupDaoService: AiUserGroupDaoService,
        private dialog: MatDialog,
        private userAuthorityDaoService: UserAuthorityDaoService,
        private customerGroupService: CustomerGroupService,
    ) {
        super();
        this.dataSource = this.aiFileDaoService;
        this.aiFileDaoService.total$.subscribe(total => {
            this.totalDocs = total;
        });
    }

    ngOnInit(): void {
        this.userAuthorityDaoService.isAdminUser().subscribe((isAdmin) => {
            this.isAdmin = isAdmin;
        });

        this.aiUserGroupDaoService.getAllByCustomerGroup(this.customerGroupService.customerGroup.id).subscribe(aiUserGroups => {
            this.aiUserGroups = aiUserGroups;
            this.aiUserGroupIdControl.setValue(this.aiUserGroups[0].id);
            this.loadTableData();
        });
        this.aiUserGroupIdControl.valueChanges.subscribe(aiUserGroupId => {
            if (aiUserGroupId) {
                this.loadTableData();
            }
        });
        this.isDefaultControl.valueChanges.subscribe(_ => {
            this.loadTableData();
        });
    }

    private clearFiles(): void {
        this.fileInput.nativeElement.value = '';
    }

    private loadTableData(): void {
        this.clearFiles();
        const isDefault = !!this.isDefaultControl.value;
        this.paginator.page.pipe(startWith(
            {pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize}
        ), filter(value => !!value)).subscribe((page: PageEvent) => {
            this.aiFileDaoService.load('aiUserGroupId', this.aiUserGroupIdControl.value, page, isDefault);
        });
    }

    hasNewFiles(): boolean {
        return this.aiFileDaoService.getAll()?.some(file => file.id === null);
    }


    sendFiles(): void {
        const aiFiles = this.aiFileDaoService.getAll()?.filter(file => {
            return file.id === null;
        });
        for (const aiFile of aiFiles) {
            aiFile.id = -1;
            this.aiFileDaoService.updateFile(aiFile);
        }
        this.uploadAllFiles(aiFiles);

    }


    delete(aiFile: AiFile): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja deletar esse arquivo?<br><br><b>' + aiFile.name + '</b>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.aiFileDaoService.delete(aiFile).subscribe(_ => {

                });
            }
        });


    }

    uploadAllFiles(aiFiles: AiFile[]): void {
        this.uploading = true;
        const aiUserGroupId = this.aiUserGroupIdControl.value;
        from(aiFiles)
            .pipe(
                concatMap(file => this.aiFileDaoService.uploadFile(file.data, file, aiUserGroupId))
            )
            .subscribe({
                next: (result) => {
                    this.aiFileDaoService.updateFile(result);
                },
                error: (erro) => {
                    this.uploading = false;
                    console.error('Erro ao processar os objetos:', erro);
                },
                complete: () => {
                    this.uploading = false;
                }
            });
    }

    onAddFiles(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        this.handleFiles(currentTarget.files);
    }

    private handleFiles(files: FileList): void {
        for (let i = 0; i < files.length; i++) {
            const aiFile: AiFile = {
                id: null,
                vectorStoreId: null,
                providerFileId: null,
                managerUserId: null,
                fileUUID: null,
                hash: null,
                data: files[i],
                name: files[i].name,
                default: null,
                purpose: 'assistants',
                size: files[i].size,
                status: null,
                createdAt: null,
            };
            this.aiFileDaoService.addFile(aiFile);
        }
    }

    openFileInput(): void {
        this.fileInput.nativeElement.click();
    }

    onCancel(): void {
        this.dialogRef.close();
    }

}
