import {Injectable} from '@angular/core';
import {BaseDao} from '../../../base-dao';
import {DomainPathService} from '../../../domain-path/domain-path.service';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs/internal/Observable';
import {CustomerGroupAiConfig} from '../../../models-ai';

@Injectable({
    providedIn: 'root'
})
export class CustomerGroupAiConfigDaoService extends BaseDao<any> {
    private aiURL = '';

    constructor(
        private domainPathService: DomainPathService,
        http: R2CloudHttpApiService,
        validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, '');
        this.aiURL = this.domainPathService.aiURL;
    }

    init(): CustomerGroupAiConfig {
        return {
            customerGroupId: null,
            instructions: null,
            model: null,
            token: null,
            createdAt: null,
            updatedAt: null,
        };
    }

    getConfig(customerGroupId: number): Observable<CustomerGroupAiConfig> {
        return this.http.get<CustomerGroupAiConfig>(this.aiURL + 'customer-group-ai-config/' + customerGroupId, null, null, true);
    }


    save(entity: CustomerGroupAiConfig): Observable<CustomerGroupAiConfig> {
        return this.http.put<CustomerGroupAiConfig>(this.aiURL + 'customer-group-ai-config/', entity, true);
    }
}

